body {
  background-color: #f8f9fa;
  font-family: 'Roboto', sans-serif;
}

.card {
  border-radius: 1rem;
}

.card-img-top {
  border: 2px solid #ddd;
}

.card-title {
  font-size: 1.5rem;
  font-weight: bold;
}

.card-subtitle {
  font-size: 1rem;
}

.card-text a {
  margin-top: 0.5rem;
  padding: 0.5rem 1rem;
}

.btn-outline-primary {
  border: 1px solid #007bff;
  color: #007bff;
}

.btn-outline-primary:hover {
  background-color: #007bff;
  color: #fff;
}

.btn-outline-secondary {
  border: 1px solid #6c757d;
  color: #6c757d;
}

.btn-outline-secondary:hover {
  background-color: #6c757d;
  color: #fff;
}

footer {
  border-top: 1px solid #e9ecef;
}
